/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.target {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.tooltip {
  --tooltip-bg-color: rgb(55 61 77 /0.8);
}

.content {
  position: relative;
  display: flex;
  z-index: 1;
  flex-direction: column;
  min-width: calc(2.5 * var(--100px));
  max-width: calc(2.75 * var(--100px));
  overflow-y: hidden;
}

.row {
  width: auto;
  margin-bottom: var(--2px);
  padding-bottom: var(--3px);

  :where(html[data-theme='lightning']) & {
    border-bottom: var(--1px) solid var(--lightning--gray-700);
  }
}

.footer {
  composes: row;
  display: flex;
  justify-content: center;
  padding-top: var(--gap-row-xsmall);
  font-size: var(--12px);
}

.row:last-child {
  border-bottom: none;
}

.pill {
  display: inline-block;
  margin: 0 var(--1px) var(--1px) 0;
}

.label {
  margin-right: var(--3px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}

.unmanagedWorkload {
  margin-left: var(--6px);
}

.SourceToDestination {
  margin-bottom: var(--2px);
  padding-bottom: var(--5px);

  :where(html[data-theme='lightning']) & {
    border-bottom: var(--1px) solid var(--lightning--gray-700);
  }
}

.arrowRight {
  color: red;
}

.src {
  display: grid;
  grid-template-columns: var(--32px) 3fr;
  align-items: center;
}

.serviceContent {
  display: grid;
  grid-template-columns: var(--24px) 2fr 3fr;
  align-items: center;
}

.sourceOrDestination {
  width: var(--18px);
  height: var(--18px);
  margin-right: var(--12px);
}

.srcOrDst {
  composes: sourceOrDestination;
  border-radius: 50%;
  background-color: var(--map-endpoint-workload);
}

.unmanagedSrcOrDst {
  composes: sourceOrDestination;
  font-size: 1.2em;
  line-height: 1.2em;
  color: var(--map-endpoint-workload);
  text-align: center;
}

.dst {
  composes: src;
}

.serviceContent {
  font-size: var(--12px);
}

.services {
  composes: row;
  margin-left: var(--10px);
}

.service:first-child {
  margin-bottom: var(--4px);
}

.process {
  margin-left: var(--32px);
}

.singleDirection {
  line-height: 1em;
  transform: translateX(-1px);
}

.biDirection {
  composes: singleDirection;
}

.link-icon {
  font-size: 1.4em;
  line-height: 0.5em;
}

.link-potentiallyBlocked-icon,
.link-deficiency-potentiallyBlocked-icon,
.link-potentiallyBlockedByBoundary-icon,
.link-deficiency-potentiallyBlockedByBoundary-icon,
.link-down-potentiallyBlocked-icon,
.link-down-deficiency-potentiallyBlocked-icon {
  composes: link-icon;
  color: var(--map-map-link-potentially-blocked);
}

.link-potentiallyBlocked-rotatePositiveDegree-svg,
.link-deficiency-potentiallyBlocked-rotatePositiveDegree-svg,
.link-potentiallyBlockedByBoundary-rotatePositiveDegree-svg,
.link-deficiency-potentiallyBlockedByBoundary-rotatePositiveDegree-svg,
.link-down-potentiallyBlocked-rotatePositiveDegree-svg,
.link-down-deficiency-potentiallyBlocked-rotatePositiveDegree-svg {
  transform: translateX(-1px) rotate(90deg);
}

.link-allowed-icon,
.link-allowedAcrossBoundary-icon,
.link-down-allowedAcrossBoundary-icon,
.link-down-allowed-icon {
  composes: link-icon;
  color: var(--map-map-link-allowed);
}

.link-deficiency-allowed-icon,
.link-deficiency-allowedAcrossBoundary-icon,
.link-down-deficiency-allowedAcrossBoundary-icon,
.link-down-deficiency-allowed-icon {
  composes: link-icon;
  color: var(--map-map-link-deficiency-allowed);
}

.link-blocked-icon,
.link-blockedByBoundary-icon,
.link-down-blocked-icon,
.link-down-deficiency-blockedByBoundary-icon {
  composes: link-icon;
  color: var(--map-map-link-blocked);
}

.link-deficiency-blocked-icon,
.link-deficiency-blockedByBoundary-icon,
.link-down-deficiency-blocked-icon {
  composes: link-icon;
  color: var(--map-map-link-deficiency-blocked);
}

.link-default-icon,
.link-unknown-icon,
.link-down-default-icon,
.link-down-unknown-icon {
  composes: link-icon;
  color: var(--map-map-link-default);
}

.link-loading-icon,
.link-down-loading-icon {
  composes: link-icon;
}

.link-bi-directional-icon {
  composes: link-icon;
  color: var(--map-map-tooltip-bi-directional-link);
}
