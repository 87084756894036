/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.status-badge {
  padding: var(--2px) var(--10px);
  line-height: 1.2;
}

.table-maxWidth_1152 {
  .status-badge {
    vertical-align: top;
    white-space: nowrap;
  }
}

.table-minWidth_1152 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaHead .cell.status {
    padding-top: var(--8px);
    white-space: nowrap;
  }

  .areaBody .cell.cellPadding.status {
    padding-top: 0;
    padding-bottom: 0;
  }

  .areaBody {
    .cell.workloads,
    .cell.containerWorkloads {
      text-align: center;
    }
  }
}

.service-link {
  composes: cellContentVExpand from 'components/Grid/Grid.css';
  display: inline-block; /* To make its marging work */
  word-break: break-all;
}
