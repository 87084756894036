/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.button-textIsHideable {
  @media (width <= 860px) {
    display: none;
  }
}

.slb-link {
  composes: cellContentVExpand from 'components/Grid/Grid.css';
  display: inline-block; /* To make its marging work */
}

.rowToEnforce .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);
  }
}
