/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

/* Shared tooltip styles */
.tooltip {
  /* Overrides tippy default arrow offset of -7px to prevent overlap on semi-transparent elements, which results in intensified bg-colors  */
  --animation-offset: var(--5px);
  --arrow-offset: -7px;
  --tooltip-content-padding-vertical: var(--8px);
  --tooltip-content-padding-horizontal: var(--12px);
  --tooltip-border-radius: var(--6px);

  position: relative;
  min-height: inherit;
  max-height: inherit;
  font-size: var(--14px);
  font-weight: var(--fontWeight);
  line-height: 1.4;
  color: var(--tooltip-color);
  outline: 0;
  border-radius: var(--tooltip-border-radius);
  background: var(--tooltip-bg-color);
  transition-property: transform, visibility, opacity;

  &[data-state='hidden'] {
    opacity: 0;
  }

  .content {
    position: relative;
    z-index: 1;
    padding: var(--tooltip-content-padding-vertical) var(--tooltip-content-padding-horizontal);
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }

  /* General Arrow Styles */
  > .arrow {
    width: var(--16px);
    height: var(--16px);
    color: var(--tooltip-bg-color);
  }

  > .arrow::before {
    position: absolute;
    content: '';
    border-style: solid;
    border-color: transparent;
  }
}

/* General Tooltip styles */
.light {
  backdrop-filter: blur(var(--offset-xsmall));

  :where(html[data-theme='lightning']) & {
    --tooltip-color: var(--lightning--gray-900);
    --tooltip-bg-color: linear-gradient(
      90.4deg,
      rgb(var(--lightning--white-rgb) / 0.9) 0.96%,
      rgb(var(--lightning--white-rgb) / 0.8) 99.91%
    );
  }
}

.dark {
  :where(html[data-theme='lightning']) & {
    --tooltip-color: var(--lightning--white);
    --tooltip-bg-color: var(--lightning--blue-gray-900);

    filter: var(--lightning--shadow-400);
  }
}

/* Classes related to tooltip position - e.g. top is used for data-placement values of 'top-start', 'top', 'top-end' */
.top {
  &[data-state='hidden'] {
    transform: translateY(calc(var(--animation-offset) * -1));
  }

  .arrow {
    bottom: 0;
  }

  .arrow::before {
    bottom: var(--arrow-offset);
    left: 0;
    border-width: var(--8px) var(--8px) 0;
    border-top-color: initial;
    transform-origin: center top;
  }

  /* This is a pattern in all placement classes. Need to modify arrow position ever so slightly to prevent minor visual issues */
  &[data-placement='top-end'] .arrow::before {
    left: var(--1px);
  }

  &[data-placement='top-start'] .arrow::before {
    left: calc(-1 * var(--1px));
  }
}

.right {
  &[data-state='hidden'] {
    transform: translateX(var(--animation-offset));
  }

  .arrow {
    left: 0;
  }

  .arrow::before {
    left: var(--arrow-offset);
    border-width: var(--8px) var(--8px) var(--8px) 0;
    border-right-color: initial;
    transform-origin: center right;
  }

  &[data-placement='right-start'] .arrow::before {
    top: calc(-1 * var(--1px));
  }

  &[data-placement='right-end'] .arrow::before {
    top: var(--1px);
  }
}

.bottom {
  &[data-state='hidden'] {
    transform: translateY(var(--animation-offset));
  }

  .arrow {
    top: 0;
  }

  .arrow::before {
    top: var(--arrow-offset);
    left: 0;
    border-width: 0 var(--8px) var(--8px);
    border-bottom-color: initial;
    transform-origin: center bottom;
  }

  &[data-placement='bottom-start'] .arrow::before {
    left: calc(-1 * var(--1px));
  }

  &[data-placement='bottom-end'] .arrow::before {
    left: var(--1px);
  }
}

.left {
  &[data-state='hidden'] {
    transform: translateX(calc(var(--animation-offset) * -1));
  }

  .arrow {
    right: 0;
  }

  .arrow::before {
    right: var(--arrow-offset);
    border-width: var(--8px) 0 var(--8px) var(--8px);
    border-left-color: initial;
    transform-origin: center left;
  }

  &[data-placement='left-start'] .arrow::before {
    top: calc(-1 * var(--1px));
  }

  &[data-placement='left-end'] .arrow::before {
    top: var(--1px);
  }
}
