/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

:root {
  /* values also being used in InstantSearch */
  --side-navigation-item-radius: var(--8px);
  --side-navigation-text: var(--13px);
}

.container {
  --side-navigation-item-margin: var(--2px);
  --side-navigation-item-height: var(--30px);
  --side-navigation-icon-scale: scale(1.2);
  --side-navigation-icon-size: var(--20px);
  --side-navigation-arrow-icon-size: var(--10px);
  --side-navigation-toggle-radius: var(--24px);
  --box-shadow-color: 47 59 76;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--side-navigation-background-color);

  :where(html[data-theme='lightning']) & {
    --side-navigation-flyout-active-bg-color: rgb(var(--lightning--blue-950-rgb) / 0.92);
    --side-navigation-search-bg-color: rgb(var(--lightning--white-rgb) / 0.9);
  }
}

.flexCenterAlign {
  display: flex;
  align-items: center;
}

.navItem {
  composes: flexCenterAlign;
  justify-content: space-between;
  height: var(--side-navigation-item-height);
  font-weight: var(--fontWeightSemiBold);
  border-radius: var(--side-navigation-item-radius);

  &.focused {
    outline: none;

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
      background: var(--lightning--blue-50);
    }
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  margin-top: var(--0px);
  padding: var(--side-navigation-padding);
  overflow: auto;
}

.expanded {
  .container {
    /* Make a side navigation 18% of the screen width, but between 220px and 260px */
    width: var(--side-navigation-width);
  }

  .search-icon {
    display: none;
  }
}

.collapsed {
  .container {
    width: var(--side-navigation-width--collapsed);
  }

  .sidenav-delimiter {
    width: var(--48px);
  }

  .content {
    align-items: center;
    padding: var(--0px);
    overflow: visible;
  }

  .menuItem,
  .item {
    display: flex;
    justify-content: center;
  }

  .name {
    display: none;
  }

  .searchInput {
    display: none;
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--offset-xsmall) var(--offset-medium);
    border: none;
    border-radius: var(--side-navigation-item-radius);
  }

  .search-wrapper {
    display: none;
  }
}

/* checkbox toggle styling */
.toggle-checkbox {
  position: absolute;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  top: var(--0px);
  right: var(--0px);
  width: var(--side-navigation-toggle-radius);
  height: var(--side-navigation-toggle-radius);
  font-size: var(--side-navigation-text);
  border-radius: var(--side-navigation-toggle-radius);
  transform: translate(50%, -50%);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-50);
    background: var(--lightning--blue-300);
  }
}

.checkboxWrapper {
  composes: flexCenterAlign;
  position: relative;
  height: calc(2 * var(--side-navigation-icon-size));
}

/* backdrop styling */
@media screen and (width < 1152px) {
  .expanded {
    z-index: 1000;

    .screenMobile {
      position: absolute;

      :where(html[data-theme='lightning']) & {
        box-shadow: var(--4px) 0 var(--16px) 0 rgb(var(--lightning--blue-gray-900-rgb) / 0.25);
      }
    }

    .backdrop {
      position: fixed;
      top: var(--0px);
      left: var(--0px);
      width: 100%;
      height: 100%;

      :where(html[data-theme='lightning']) & {
        background-color: rgb(var(--lightning--blue-gray-900-rgb) / 0.25);
      }
    }
  }
}

/* Navigation Item CSS */
.menuItem {
  width: 100%;
  padding: var(--0px);
}

.menu {
  margin-top: var(--offset-xsmall);
  padding: var(--0px);

  .menuItem-link {
    margin-top: var(--side-navigation-item-margin);
    padding: var(--offset-small) var(--page-horizontal-padding);
    white-space: nowrap;
    list-style-type: none;
    /* Add touch-action: manipulation for a fast reacting touch experience */
    /* https://twitter.com/argyleink/status/1405881231695302659 */
    touch-action: manipulation;
  }
}

.menuItem {
  margin-top: var(--side-navigation-item-margin);
}

.menu,
.menuItem {
  list-style: none;
  cursor: pointer;
  user-select: none; /* Prevent item text selection on fast click */

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.menuName {
  composes: flexCenterAlign;
}

.menuOpen {
  .menuName {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
    }
  }
}

.name {
  margin-left: var(--offset-small);
}

.menu-icon {
  font-size: var(--side-navigation-icon-size);
}

/* sidenav items with menu */
.flyoutMenu-menu {
  width: 100%;
  height: var(--0px);

  > .flyoutMenu-dropdown {
    width: 100%;
    margin-top: var(--0px);
    box-shadow: unset;
    background: unset;
    backdrop-filter: unset;

    > .flyoutMenu-itemsContainer {
      width: 100%;
      margin: auto;

      .flyoutMenu-itemContent {
        flex-grow: 0;
      }

      .flyoutMenu-item {
        display: flex;
        justify-content: center;
        padding: 0;
        list-style: none;
        cursor: pointer;
        user-select: none; /* Prevent item text selection on fast click */

        &:not(:first-of-type) {
          margin-top: var(--offset-small);
          margin-bottom: var(--offset-small);
        }

        /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
        :where(html[data-theme='lightning']) & {
          color: var(--lightning--blue-gray-600);
        }
      }

      .flyoutMenu-itemSelectable:focus {
        background-color: var(--lightning--blue-50);
      }

      .menuItem-activeItemContent {
        /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
        :where(html[data-theme='lightning']) & {
          color: var(--lightning--blue-900);
          background: var(--lightning--blue-gradient);
        }
      }

      .menuItem-activeDropdown {
        outline: none;
      }
    }
  }
}

.flyoutMenu-itemSelectable {
  &:focus {
    .menu-icon {
      :where(html[data-theme='lightning']) & {
        color: var(--lightning--blue-900);
      }
    }
  }
}

.flyoutMenu-drillIcon {
  display: none;
}

.flyoutMenu-dropdown {
  opacity: 1;
  border: none;
  border-radius: var(--side-navigation-item-radius);
  background: var(--side-navigation-flyout-active-bg-color);
  backdrop-filter: blur(var(--side-navigation-item-radius));

  :where(html[data-theme='lightning']) & {
    box-shadow: var(--lightning--box-shadow-200);
  }
}

.flyoutMenu-item {
  color: white;
}

.menuInformation,
.menuItem-link {
  composes: navItem;
  padding: var(--offset-xsmall) var(--offset-medium);

  > .menuNameContainer {
    padding: var(--0px);
  }
}

.menuItem {
  &:hover {
    cursor: pointer;
    border-radius: var(--8px);

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
      background: var(--lightning--blue-50);
    }
  }
}

.upArrow-icon,
.downArrow-icon {
  font-size: var(--side-navigation-arrow-icon-size);
}

.upArrow-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.icon-tooltip {
  margin-left: var(--6px);
  padding: var(--2px) var(--6px);
  border-radius: var(--side-navigation-item-radius);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
    background: var(--lightning--blue-gray-950);
  }

  .icon-arrow {
    display: none;
  }
}

.menuItem-activeItemContent:not(:focus) {
  border-radius: var(--side-navigation-item-radius);
  background: var(--side-navigation-flyout-active-bg-color);
}

.menuItem-active {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
    background: var(--lightning--blue-gradient);
  }
}

.activeGroup {
  &.menuClosed {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
      background: var(--lightning--blue-gradient);
    }

    .sideNav-itemContent {
      background: none;
    }
  }

  .flyoutMenu-menu {
    border-radius: var(--side-navigation-item-radius);

    :where(html[data-theme='lightning']) & {
      background: var(--lightning--blue-gradient);
    }
  }

  .flyoutMenu-trigger {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
    }
  }
}

/* search input */
.menuItemsContainer {
  overflow: hidden;
}

.search-icon {
  font-size: var(--side-navigation-icon-size);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.search-wrapper {
  width: 100%;
  height: var(--side-navigation-item-height);
}

.search-input {
  font-size: var(--side-navigation-text);
  border-radius: var(--side-navigation-item-radius);
  background-color: var(--side-navigation-search-bg-color);

  :where(html[data-theme='lightning']) & {
    --input-placeholder-color: var(--lightning--blue-gray-600);
    --input-placeholder-hover-color: var(--lightning--blue-gray-500);

    color: var(--lightning--blue-gray-300);
    border: var(--1px) solid var(--lightning--blue-gray-300);
  }

  &.search-withIcon {
    padding-top: var(--offset-xsmall);
    padding-bottom: var(--offset-xsmall);
    font-size: var(--side-navigation-text);
    font-style: normal;
  }
}

.search-icon-icon {
  --icon-padding-left: var(--2px);

  padding-top: var(--0px);
  padding-left: var(--offset-xsmall);
  font-size: var(--side-navigation-icon-size);
}

.search {
  composes: navItem;
  justify-content: flex-start;
  margin: var(--0px) var(--0px) var(--10px) var(--0px);

  &:hover {
    cursor: pointer;

    .search-icon {
      padding: var(--3px) var(--10px);
      border-radius: 6px;

      :where(html[data-theme='lightning']) & {
        color: var(--lightning--blue-900);
        background: var(--lightning--blue-50);
      }
    }
  }
}
