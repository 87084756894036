/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
:root {
  /* Variable --input-line-height is used by FormLabel and input text */
  --optionSelector-line-height: var(--22px);
  --optionSelector-vertical-padding: var(--4px);
  --optionSelector-height: calc(
    var(--2px) + 2 * var(--optionSelector-vertical-padding) + var(--optionSelector-line-height)
  );
  --optionSelector-border-radius: var(--8px);
  --optionSelector-max-height: calc(1.7 * var(--100px));
}

.dropdownWrapper {
  position: absolute;
  z-index: 1000;
  top: var(--28px);
  width: 100%; /* original 275px; */
  margin-top: var(--8px);
  list-style: none;
  opacity: 1;
  overflow: hidden;
  border-radius: var(--optionSelector-border-radius);

  :where(html[data-theme='lightning']) & {
    box-shadow: 0 var(--4px) var(--24px) rgba(var(--lightning--gray-600-rgb) / 0.25);
    background-color: var(--lightning--white);
  }
}

.dropdown {
  max-height: var(--optionSelector-max-height);
  padding: var(--8px);
  overflow-y: auto;

  &.hiddenScroll {
    overflow-y: hidden; /* use this to show the first item in the scroll window for arrow down key in <OptionSelector> on parent div */
  }
}

.customPicker {
  width: calc(27.5 * var(--10px));
  overflow-y: visible;
}

.iconSvgArrowDown {
  width: 0.7em;
  height: 0.5em;
  vertical-align: unset;
}

.selectorMainWrapper {
  position: relative;
}

.selectorMain {
  display: flex;
  align-items: center;
  padding-right: var(--10px);
  cursor: pointer;
  border: var(--form-input-border);
  border-radius: var(--optionSelector-border-radius);

  &.selectorHighlight {
    :where(html[data-theme='lightning']) & {
      border-color: var(--lightning--blue-500);
      box-shadow: 0 0 0 var(--1px) var(--lightning--blue-100);
    }
  }

  &.selectorError {
    border-color: var(--form-error-border-color);

    &.selectorHighlight {
      :where(html[data-theme='lightning']) & {
        border-color: var(--lightning--red-500);
        box-shadow: 0 0 0 var(--1px) var(--lightning--red-100);
      }
    }
  }

  &.selectorActive {
    border: 1px solid var(--form-focus-border-color);
  }

  &:focus {
    outline: 1px solid var(--form-focus-border-color);
  }

  :where(html[data-theme='lightning']) & {
    border-color: var(--lightning--blue-gray-400);

    &.selectorError {
      border-color: var(--lightning--red-400);
    }

    &:not(.selectorHighlight) {
      &:hover {
        border-color: var(--lightning--blue-200);
      }

      &.selectorError:hover {
        border-color: var(--lightning--red-300);
      }
    }
  }
}

.disabled {
  pointer-events: none;
  border: var(--form-input-border);

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-gray-50);
  }
}

.selectMainLabel {
  flex: 1 1 auto;
  padding: var(--optionSelector-vertical-padding) var(--6px);
  line-height: var(--optionSelector-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.selectorPlaceHolder {
  font-weight: var(--fontWeightLight);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-500);
  }
}

/* Utility class to align content vertically with the middle of the selector */
.selectorAligned {
  display: inline-flex;
  align-items: center;
  height: calc(var(--optionSelector-height) - var(--1px));
}
