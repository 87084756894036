/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
.pillGap {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

.pseudoSelector {
  composes: pillGap;
  composes: search disabled from 'containers/Selectors/Selectors.css';
  padding: var(--2px);
}

/* override pill content's style, so we can show 'disabled' pill in disabled pseudoselector */
.pill {
  :where(html[data-theme='lightning']) & {
    --pill-content-color: var(--lightning--gray-500);
  }
}
