/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  :where(html[data-theme='lightning']) & {
    --map-legend-background: var(--lightning--white);
  }
}

.container {
  --legend-v-padding: var(--offset-medium);
  --legend-h-padding: var(--offset-x-large);

  display: grid;
  grid-template-columns: min-content auto min-content auto min-content auto min-content auto;
  grid-template-areas:
    't2 t2 t2 t2 t2 t2 t2 t2'
    'gd sd ge se g3 s3 g4 s4'
    'g2 s2 gf sf g6 s6 g7 s7'
    'g5 s5 gg sg g9 s9 ga sa'
    'g8 s8 gh sh gb sb .  .'
    '.  .  .  .  gc sc .  .';
  align-items: center;
  width: calc(8.35 * var(--100px));
  max-height: calc(100vh - 2 * var(--100px));
  padding: var(--legend-v-padding) var(--legend-h-padding);
  overflow-y: scroll;
  border-radius: var(--2px);
  box-shadow: var(--shadow-depth-4);
  background-color: var(--map-legend-background);
  column-gap: var(--gap-column-medium);
  row-gap: var(--gap-row-small);

  &.basicMode {
    grid-template-columns: min-content auto min-content auto min-content auto;
    grid-template-areas:
      't2 t2 t2 t2 t2 t2'
      'gd sd g3 s3 g4 s4'
      'g2 s2 g6 s6 g7 s7'
      'g5 s5 g9 s9 ga sa'
      'g8 s8 gb sb .  .'
      '.  .  gc sc .  .';
    width: calc(6.1 * var(--100px));
  }
}

.containerCSframe {
  composes: container;
  column-gap: var(--gap-column-x-large);
}

.t1 {
  grid-area: t1;
}

.t2 {
  grid-area: t2;
}

.g1 {
  grid-area: g1;
}

.s1 {
  grid-area: s1;
}

.g2 {
  grid-area: g2;
}

.s2 {
  grid-area: s2;
}

.g3 {
  grid-area: g3;
}

.s3 {
  grid-area: s3;
}

.g4 {
  grid-area: g4;
}

.s4 {
  grid-area: s4;
}

.g5 {
  grid-area: g5;
}

.s5 {
  grid-area: s5;
}

.g6 {
  grid-area: g6;
}

.s6 {
  grid-area: s6;
}

.g7 {
  grid-area: g7;
}

.s7 {
  grid-area: s7;
}

.g8 {
  grid-area: g8;
}

.s8 {
  grid-area: s8;
}

.g9 {
  grid-area: g9;
}

.s9 {
  grid-area: s9;
}

.ga {
  grid-area: ga;
}

.sa {
  grid-area: sa;
}

.gb {
  grid-area: gb;
}

.sb {
  grid-area: sb;
}

.gc {
  grid-area: gc;
}

.sc {
  grid-area: sc;
}

.gd {
  grid-area: gd;
}

.sd {
  grid-area: sd;
}

.ge {
  grid-area: ge;
}

.se {
  grid-area: se;
}

.gf {
  grid-area: gf;
}

.sf {
  grid-area: sf;
}

.gg {
  grid-area: gg;
}

.sg {
  grid-area: sg;
}

.gh {
  grid-area: gh;
  margin-top: var(--3px);
  margin-left: var(--3px);
}

.sh {
  grid-area: sh;
}

.containerCSframe {
  composes: container;
  grid-template-areas:
    't2 t2 t2 t2 t2 t2'
    'g2 s2 g3 s3 g4 s4'
    'g5 s5 g6 s6 g7 s7'
    'g8 s8 g9 s9 ga sa'
    '.  .  gb sb .  .'
    '.  .  gc sc .  .';
  column-gap: var(--gap-column-x-large);
}

.lineBreak {
  grid-column: 1 / -1;
  margin: var(--3px) calc(-1 * var(--legend-h-padding));

  :where(html[data-theme='lightning']) & {
    border-bottom: 1px solid var(--lightning--gray-400);
    box-shadow: 0 1px 0 0 var(--lightning--gray-50);
  }
}

.title {
  composes: bold from 'utils.css';
  grid-column: auto / span 2;
  margin-left: calc(-1 * var(--legend-h-padding) / 2);
  text-transform: uppercase;
}

.titleFullWidth {
  composes: title;
  grid-column: 1 / -1;
}

.collapsedGroupWrapper {
  composes: centeredFlex from 'utils.css';
  width: var(--45px);
  height: var(--45px);
  border-radius: 50%;
  background-color: white;
}

.collapsedGroups-icon {
  composes: centeredFlex from 'utils.css';
  width: var(--45px);
  height: var(--45px);
  font-size: 1.7em;
  border-radius: 50%;
}

.collapsedGroupsAppGroup-icon {
  composes: collapsedGroups-icon;

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-400);
  }
}

.workload-icon {
  composes: centeredFlex from 'utils.css';
  width: var(--45px);
  height: var(--45px);
  font-size: 1.4em;
  color: var(--map-endpoint-workload);
  border-radius: 50%;
  background-color: var(--map-endpoint-workload-background);
}

.cloud-workload-icon {
  composes: workload-icon;
  color: var(--map-endpoint-cloudWorkload);
}

.workloadVisibilityOnly-icon {
  composes: workload-icon;
  box-shadow: inset 0 0 0 4px rgb(223 162 118);
}

.workloadSelective-icon {
  composes: workload-icon;
  box-shadow: inset 0 0 0 4px rgb(127 178 198);
}

.workloadFull-icon {
  composes: workload-icon;
  box-shadow: inset 0 0 0 4px rgb(73 93 104);
}

.workloadContainer-icon {
  composes: workload-icon;
}

.workloadContainer-svg {
  transform: scale(1.2);
}

.workloadContainerVisibilityOnly-icon {
  composes: workloadVisibilityOnly-icon;
}

.workloadContainerVisibilityOnly-svg {
  transform: scale(1.2);
}

.workloadContainerSelective-icon {
  composes: workloadSelective-icon;
}

.workloadContainerSelective-svg {
  transform: scale(1.2);
}

.workloadContainerFull-icon {
  composes: workloadFull-icon;
}

.workloadContainerFull-svg {
  transform: scale(1.2);
}

.link-icon {
  composes: centeredFlex from 'utils.css';
  width: var(--45px);
  height: var(--45px);
}

.link-svg {
  cursor: pointer;
  transform: scale(2.5);
}

.linkBlocked-icon {
  composes: link-icon;
  color: var(--map-map-link-blocked);
}

.linkBlocked-svg {
  composes: link-svg;
}

.link-deficient-Blocked-icon {
  composes: link-icon;
  color: var(--map-map-link-deficiency-blocked);
}

.link-deficient-Blocked-svg {
  composes: link-svg;
}

.linkPotentiallyBlocked-icon {
  composes: link-icon;
  color: var(--map-map-link-potentially-blocked);
}

.link-deficient-PotentiallyBlocked-icon {
  composes: link-icon;
  color: var(--map-map-link-potentially-blocked);
}

.linkPotentiallyBlocked-svg {
  composes: link-svg;
}

.link-deficient-PotentiallyBlocked-svg {
  composes: link-svg;
}

.linkNotCalculated-icon {
  composes: link-icon;
  color: var(--map-legend-link-not-calculated);
}

.linkNotCalculated-svg {
  composes: link-svg;
}

.linkAllowed-icon {
  composes: link-icon;
  color: var(--map-map-link-allowed);
}

.linkAllowed-svg {
  composes: link-svg;
}

.link-deficient-Allowed-icon {
  composes: link-icon;
  color: var(--map-map-link-deficiency-allowed);
}

.link-deficient-Allowed-svg {
  composes: link-svg;
}
