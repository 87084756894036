/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.pill {
  position: relative;
  display: inline-block;
  min-width: var(--50px);
  font-size: var(--12px);
  line-height: var(--24px);
  color: var(--pill-content-color);
  text-align: center;
  border-radius: var(--15px);
  background-color: var(--pill-content-background-color);

  :where(html[data-theme='lightning']) & {
    --pill-content-color: var(--lightning--white);
    --pill-content-color-hover: var(--pill-content-color);
    --pill-content-color-active: var(--pill-content-color);
    --pill-content-background-color: transparent;
    --pill-content-background-color-hover: var(--pill-content-background-color);
    --pill-content-background-color-active: var(--pill-content-background-color);
  }
}

/* Vulnerability types */
.critical {
  :where(html[data-theme='lightning']) & {
    --pill-content-background-color: var(--lightning--red-700);
    --pill-content-background-color-hover: var(--lightning--red-600);
    --pill-content-background-color-active: var(--lightning--red-800);
  }
}

.high {
  :where(html[data-theme='lightning']) & {
    --pill-content-background-color: var(--lightning--red-500);
    --pill-content-background-color-hover: var(--lightning--red-400);
    --pill-content-background-color-active: var(--lightning--red-600);
  }
}

.medium {
  :where(html[data-theme='lightning']) & {
    --pill-content-background-color: var(--lightning--orange-500);
    --pill-content-background-color-hover: var(--lightning--orange-400);
    --pill-content-background-color-active: var(--lightning--orange-600);
  }
}

.low {
  :where(html[data-theme='lightning']) & {
    --pill-content-background-color: var(--lightning--green-600);
    --pill-content-background-color-hover: var(--lightning--green-500);
    --pill-content-background-color-active: var(--lightning--green-700);
  }
}

.info {
  :where(html[data-theme='lightning']) & {
    --pill-content-background-color: var(--lightning--blue-600);
    --pill-content-background-color-hover: var(--lightning--blue-500);
    --pill-content-background-color-active: var(--lightning--blue-700);
  }
}

.none {
  :where(html[data-theme='lightning']) & {
    --pill-content-color-hover: var(--lightning--white);
    --pill-content-background-color: var(--lightning--gray-500);
    --pill-content-background-color-hover: var(--lightning--gray-400);
    --pill-content-background-color-active: var(--lightning--gray-600);
  }
}

.syncing-icon {
  font-size: var(--16px);
}

/* Internet cloud icon */
.internet-icon {
  position: absolute;
  top: -42%;
  right: -15%;
  font-size: var(--18px);
  color: var(--internet-icon-bg-color);
  pointer-events: none;

  :where(html[data-theme='lightning']) & {
    --internet-icon-bg-color: var(--lightning--blue-gray-700);
  }
}

.internet-svg {
  width: var(--20px); /* To fit stroke */
  stroke-width: 3px;
  stroke-linejoin: round;

  :where(html[data-theme='lightning']) & {
    stroke: var(--lightning--white);
  }
}

/* Clickable state */
.clickable {
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus-visible:not(:active) {
    :where(html[data-theme='lightning']) & {
      /* Add custom focus outline */
      box-shadow:
        0 0 3px 3px rgb(var(--lightning--blue-500-rgb) / 0.5),
        0 0 0 1px var(--lightning--blue-600);
    }
  }

  &:not(:active):hover,
  &:not(:active):focus {
    color: var(--pill-content-color-hover);
    background-color: var(--pill-content-background-color-hover);
  }

  &:active {
    color: var(--pill-content-color-active);
    background-color: var(--pill-content-background-color-active);
  }
}
