/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.scopePicker {
  composes: gapLarge gapAlbatross from 'utils.css';
}

.labelSelector {
  flex-grow: 1;
}
