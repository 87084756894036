/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.detailPanel {
  /* NOTE: !important needed to override re-resizable position:relative */
  position: absolute !important; /* stylelint-disable-line declaration-no-important */
  display: flex;
  top: var(--32px);
  right: 0;
  bottom: 0;
  padding: var(--10px) var(--20px);
  border-radius: var(--20px) var(--0px) var(--0px) var(--20px);
  box-shadow: var(--shadow-depth-3);

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--white);
  }
}

.container {
  display: flex;
  flex-grow: 1;
}

.rulesContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  bottom: var(--27px);
  left: calc(-1 * var(--12px));
  width: var(--24px);
  height: var(--24px);
  border: none;
  border-radius: var(--24px);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-300);
  }

  .icon {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
    }
  }

  &:hover {
    cursor: pointer;

    .icon {
      :where(html[data-theme='lightning']) & {
        color: var(--lightning--blue-gray-600);
      }
    }
  }
}
