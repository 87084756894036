/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

:root {
  --map-toolbar-button-z-index: calc(var(--map-toolbar-z-index) + 1); /* must be greater than --map-toolbar-z-index */

  :where(html[data-theme='lightning']) & {
    --map-toolbar-button-color: var(--text-color);
    --map-toolbar-button-color-disabled: rgb(var(--lightning--gray-900-rgb) / 0.6);
    --map-toolbar-button-background-color: transparent;
    --map-toolbar-button-background-color-hover: var(--lightning--blue-gray-200);
    --map-toolbar-button-background-color-active: var(--lightning--blue-gray-300);
    --map-toolbar-button-background-color-disabled: rgb(var(--lightning--blue-gray-200-rgb) / 0.25);
  }
}

html[data-theme='lightning'] {
  :where(html[data-theme='lightning']) & {
    --map-toolbar-button-menu-item-background-color-hover: var(--lightning--blue-50);
  }
}

.toolBarButton {
  position: relative;
  display: flex;
  align-self: stretch;
  flex-wrap: nowrap;
  justify-content: stretch;
}

.toolBarButton > nav {
  display: flex;
  align-items: stretch;
}

.toolBarButton .menuIcon-icon {
  font-size: 0.7em;
}

.toolBarButton .button {
  --button-color: var(--map-toolbar-button-color);
  --button-color-disabled: var(--map-toolbar-button-color-disabled);
  --button-background-color: var(--map-toolbar-button-background-color);
  --button-background-color-disabled: var(--map-toolbar-button-background-color-disabled);
  --button-background-color-hover: var(--map-toolbar-button-background-color-hover);
  --button-background-color-active: var(--map-toolbar-button-background-color-active);
  --button-border-color: transparent;
  --button-border-color-hover: transparent;
  --button-border-color-active: transparent;
  --button-focus-glow: none;

  padding-right: var(--10px);
  padding-left: var(--10px);
  color: var(--button-color);
  border-color: transparent;
  background-color: transparent;

  .icon {
    font-size: calc(var(--fontSize) * 0.7);
    font-weight: var(--fontWeight);

    & svg {
      vertical-align: middle;
    }
  }

  .icon-icon {
    font-size: var(--fontSize);
  }

  &:hover,
  &.active {
    color: var(--text-color);
    border-color: transparent;

    :where(html[data-theme='lightning']) & {
      background-color: var(--button-background-color-hover);
    }
  }

  &:active {
    color: var(--text-color);
    border-color: transparent;

    .icon {
      color: var(--text-color);
    }
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:active {
    color: var(--map-toolbar-button-color-disabled);
  }

  > div,
  > div > div {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  > .noOverflowContainer {
    flex-shrink: 1;
  }

  > div > .progressWrapper {
    border-radius: calc(var(--1px) * 1.5);
  }
}

.open .button {
  background-color: var(--map-toolbar-button-background-color-hover);
}

.toolBarButton .menu-dropdownWithArrow {
  margin-top: 0;

  &::before {
    display: none;
  }
}

.button .text + .icon .icon-icon {
  font-size: calc(var(--fontSize) * 0.7);
  font-weight: var(--fontWeight);
}

.button .menuIcon-icon {
  display: flex;
  align-self: stretch;
  align-items: center;
}

.itemContent,
.itemContent .text,
.checkbox {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.checkbox:not(.checked) .customBox-icon {
  visibility: hidden;
}

.item:hover {
  background-color: var(--map-toolbar-button-menu-item-background-color-hover);
}

.labelSubText {
  font-size: var(--12px);
  overflow-wrap: break-word;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.options-icon {
  font-size: var(--18px);
}

.optionsWithIcon {
  display: flex;
  align-items: center;
}

.optionLabel {
  padding-left: var(--4px);
}
