/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

:root {
  --app-flex-shrink: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  /* Support displaying line breaks, all spaces, etc in user defined data. https://jira.illum.io/browse/EYE-68589 */
  composes: breakSpaces from 'utils.css';
  display: flex;
  /* App is equal to window height, independent scrollbars are inside side nav and container */
  height: 100vh;
  min-height: 100vh;
  /* Make app appear smoothly */
  animation: fadeIn 0.25s linear forwards;
}

.container {
  --app-flex-shrink: 1;

  /* Make content div take as much place as possible to fill the page using flex */
  display: flex;
  flex: 1 var(--app-flex-shrink) auto;
  flex-direction: column;
  width: 100%;
  /* We now do scrolling inside the main container */
  overflow: auto;

  :where(html[data-theme='lightning']) & {
    filter: var(--lightning--shadow-50);
  }
}

.containerFrame {
  /* Use logical properties for the frame radius (to flip in rtl) */
  border-start-start-radius: var(--app-border-radius);
  border-end-start-radius: var(--app-border-radius);

  :where(html[data-theme='lightning']) & {
    box-shadow: -2px 0 8px rgb(var(--lightning--blue-gray-400-rgb) / 0.12);
    background-color: var(--lightning--white);
  }
}

.content {
  composes: gapMedium paddingMediumTop from 'utils.css';

  /*
   * Set `flex-shrink` to `0` to prevent some browsers from
   * letting these items shrink to smaller than their content's default
   * minimum size. See http://bit.ly/1Mn35US for details.
   */
  flex: 1 0 auto;
  margin-bottom: var(--48px);

  /* Add more padding to the content */
  padding-inline-start: var(--page-horizontal-padding);
  padding-inline-end: var(--page-horizontal-padding);

  /* Make content appear smoothly too */
  animation: fadeIn 0.25s linear forwards;
}

.contentFrame {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }

  /* On tablets and bigger, content should have margin from body and rounded borders */
  @media screen and (width >= 768px) {
    margin: 0 var(--9px) var(--20px); /* add 1 px for border - e.g. var(--9px) becomes ~10px */
    border-radius: 0 0 var(--4px) var(--4px); /* Round bottom corners */

    :where(html[data-theme='lightning']) & {
      border: var(--1px) solid var(--lightning--blue-gray-200);
      border-top: none;
    }
  }

  @media screen and (width >= 1200px) {
    margin: 0 var(--9px) var(--9px);
  }
}
