/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.header {
  composes: gapMedium gapHorizontalWrap from '~utils.css';
  align-items: baseline;
  word-break: break-all;
}

.title {
  composes: bold from '~utils.css';
  font-size: var(--16px);
}

.loading {
  display: flex;
  justify-content: center;
  padding: var(--30px) 0;
}

.banner {
  padding: var(--30px) 0;
}

.picker {
  width: calc(2 * var(--100px));

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }
}

.pickerDropdown {
  max-height: calc(3 * var(--100px));
}

.tabPanel {
  --tabpanel-background-color: inherit;

  composes: cardWidth from '~components/Card/Card.css';
}

.progress-graphBar {
  margin: var(--5px) 0;
}

.more {
  align-self: center;
}

.flows {
  width: var(--50px);
  text-align: end;
}

.policy {
  composes: bold from '~utils.css';
}

.list {
  padding-left: var(--10px);
}

.groups {
  margin: var(--5px) var(--10px);
}

.gridWrapper {
  composes: cardWidth from '~components/Card/Card.css';
  flex: 1 1 auto;

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--white);
  }
}

/* In two-columns layout make grid scrollable separately */
@media (width >= 961px) {
  .gridWrapper {
    flex: 1 1 0;
    overflow: auto;
  }
}
