/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.button-textIsHideable {
  @media (width <= 860px) {
    display: none;
  }
}

.command {
  display: flex;
  justify-content: space-between;

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-50);
  }
}

.content {
  font-size: var(--13px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.text {
  padding: var(--5px) var(--6px);
  line-height: var(--20px);
  word-break: break-all;
}

.dropdown {
  max-height: calc(2.6 * var(--100px));
}

.container {
  composes: gap centeredFlex from 'utils.css';
  padding: var(--30px) var(--50px);
  font-size: 1.2rem;
  text-align: center;
}

.toolBar {
  padding: var(--8px) var(--0px);
}
